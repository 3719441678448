import { CheckBox, FormatLineSpacing, School, SupportAgent, Timelapse, UploadFile } from "@mui/icons-material";
import { Grid, Paper, Typography, Divider, Box, Stack, useTheme } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ImageContext } from "../../context/ImageContext";

const FreePlan = () => {
  const theme = useTheme();
  const { paymentPeriod } = useContext(ImageContext);

  const [billed, setBilled] = useState("Billed monthly");

  const plan = {
    name: 'Free',
    features: [
      '5 credits/month',
      'No videos',
      'No videos',
      'Max. 50 characters',
      'no support',
      'no knowledgebase',
      'no top-up option',
    ],
  };

  useEffect(() => {
    if (paymentPeriod === "monthly") {
      setBilled('$0/month');
    } else {
      setBilled('$0/year');
    }

  }, [paymentPeriod]);

  return (
    <Grid item xs={12} sm={6} md={2.3} pb={2}>
      <Paper sx={{ padding: 2, mt: '19px' }}>
        <Typography variant="h3" component="h3">
          {plan.name}
        </Typography>
        <Typography variant="h5">
          {billed}
        </Typography>

        <Typography variant="caption" textAlign={'center'}>
          {plan.features[6]}
        </Typography>

        <Box pt={2} pb={2}>
          <Divider />
        </Box>

        <Stack direction="row" spacing={1}>
          <CheckBox style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[0]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <UploadFile style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[1]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Timelapse style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[2]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <FormatLineSpacing style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[3]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <School style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[5]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <SupportAgent style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[4]}
          </Typography>
        </Stack>

      </Paper>
    </Grid>
  );
}

export default FreePlan;