import { ThemeProvider } from '@mui/material';
import { ImageDispatchContext, ImageProvider } from './context/ImageContext';
import { Header } from './components/header';
import { BodyBox } from './components/bodybox';
import { CustomTheme } from './theme/theme';
import { Footer } from './components/footer';
import MainPage from './components/main-page';
import { BrowserRouter } from 'react-router-dom';
import { Outlet, Route, Routes } from "react-router-dom";
import PrivacyPolicyPage from './components/privacy-policy';
import AGBPage from './components/agb';
import ImprintPage from './components/imprint';
import { NotificationBar } from './components/header/components/notificationBar';
import { CookiesProvider, useCookies } from "react-cookie";
import { useContext, useEffect } from 'react';
import OnlineShop from './components/landingpages/online-shop';
import LinkedIn from './components/landingpages/linkedIn';
import Instagram from './components/landingpages/instagram';
import Facebook from './components/landingpages/facebook';
import SocialMedia from './components/landingpages/social-media';
import India from './components/landingpages/india';
import BusinessDe from './components/landingpages/business-de';
import Fashion from './components/landingpages/fashion';
import Business from './components/landingpages/business';
import Photography from './components/landingpages/photography';
import Arabic from './components/landingpages/arabic';
import Reels from './components/landingpages/reels';
import TikTok from './components/landingpages/tiktok';
import Videos from './components/landingpages/videos';
import { Blog } from './components/blog';
import BlogPost from './components/blog/blogPost';
import Demo from './components/tool';
import Investor from './components/investor';

const App = () => {
  return (
    <CookiesProvider>
      <ThemeProvider theme={CustomTheme.getTheme()}>
        <ImageProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />} >
                <Route index element={<MainPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/legal-info" element={<ImprintPage />} />
                <Route path="/terms-and-conditions" element={<AGBPage />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/demo" element={<Demo />} />
                <Route path="/blog/:postName" element={<BlogPost />} />
                <Route path="/online-shop" element={<OnlineShop />} />
                <Route path="/linkedin" element={<LinkedIn />} />
                <Route path="/business" element={<Business />} />
                <Route path="/instagram" element={<Instagram />} />
                <Route path="/facebook" element={<Facebook />} />
                <Route path="/social-media" element={<SocialMedia />} />
                <Route path="/india" element={<India />} />
                <Route path="/business-de" element={<BusinessDe />} />
                <Route path="/fashion" element={<Fashion />} />
                <Route path="/photography" element={<Photography />} />
                <Route path="/arabic" element={<Arabic />} />
                <Route path='/reels' element={<Reels />} />
                <Route path='/tiktok' element={<TikTok />} />
                <Route path='/videos' element={<Videos />} />
                <Route path='/investors' element={<Investor />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ImageProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
}

const Layout = () => {
  const dispatch = useContext(ImageDispatchContext);

  const [cookies, setCookie] = useCookies(['referalID']);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch({ type: 'CHANGE_NOTIFICATIONBAR_HIDDEN', notificationBarHidden: false });
    }, 8000);

    // Clean up the timeout when the component is unmounted
    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch]);

  useEffect(() => {
    // Check if the global variable 'rewardful' is available
    if (window['rewardful']) {
      window['rewardful']('ready', function () {
        if (window['Rewardful'] && window['Rewardful'].referral) {
          console.log('Current referral ID: ', window['Rewardful'].referral);
          setCookie('referalID', window['Rewardful'].referral);
        } else {
          console.log('No referral present.');
        }
      });
    } else {
      console.warn('rewardful is not defined. Make sure the script is loaded properly.');
    }
  }, []);


  return (
    <>
      <NotificationBar />
      <Header />
      <BodyBox>
        <Outlet />
      </BodyBox>
      <Footer />
    </>
  )
}

export default App;